<template>
  <div class="mainBox">
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>
    <div class="shownum">
      <p>{{this.text}} 筛选结果（<span class="red">{{this.group}}</span>组 ）</p>
      <button class="button" @click="copy">复制</button>
    </div>
    <p v-for="(item,index) in menuList" :key="index">
      <a v-for="(itemNum,index) in item" :key="index">{{itemNum}}</a>
    </p>
  </div>
</template>

<script>
import { showToast } from 'vant';
import vHeader from "@/components/header.vue";
import isWeixin from "@/utils/utils";
export default {
  components:{
    vHeader
  },
  name: "showNumIndex",
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
    let par = this.$store.state.payWay.payType;
    let showList = [];
    switch (par) {
      case 1:
        showList = this.$store.state.payWay.fourDecComputed;
        this.text='四定';
        break;
      case 2:
        showList = this.$store.state.payWay.fourShowComputed;
        this.text='四现';
        break;
      case 3:
        showList = this.$store.state.payWay.threeDecComputed;
        this.text='三定';
        break;
      case 4:
        showList = this.$store.state.payWay.threeShowComputed;
        this.text='三现';
        break;
      case 5:
        showList = this.$store.state.payWay.twoDecComputed;
        this.text='二定';
        break;
      case 6:
        showList = this.$store.state.payWay.twoShowComputed;
        this.text='二现';
        break;
    }
    this.group = showList.length;
    this.lastall=showList;
    this.lastall=this.lastall.filter((item)=>{
        
        return item
      
      
    });
    

    var x = 0;
    let numList = [];
    let allNum = showList;
    for (var i=0;i<allNum.length;i++){
      numList.push(allNum[i]);
      x++;
      if (x === 6){
        this.menuList.push(numList);
        numList = [];
        x = 0;
      }
      if (i === allNum.length-1)
      {
        this.menuList.push(numList);
        numList = [];
        x = 0;
      }
    }
  },
  data(){
    return{
      title: this.$route.meta.title,
      menuList:[],
      group: 0,
      lastall:[],
      text:''
    }
  },
  methods:{
    copy(){
      this.$toast('已复制到剪切板')
      this.$copyText('['+this.text+' 共'+this.group+'组号码]\n'+this.lastall+'\n---感谢您使用【好劲抽】包奖王，祝君中奖').then(function (e) {
          // alert('已复制到剪切板')
          // console.log(e)
        }, function (e) {
          this.$toast('复制失败！')
          // alert('Can not copy')
          // console.log(e)
        })
    }
  }
}
</script>

<style lang="less">
.red{
  color: red;
}
.mainBox{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  p{
    padding: 0;
    margin: 0;

    a{
      font-size: 12px;
      margin: 12px;
    }
  }
  .shownum{
    
    p{
      font-size: 0.3rem;
      display: inline-block;
    }
    .button{
      font-size: 0.35rem;
      display: inline-block;
      margin-left:4rem;
      border: none;
      color:white;
      background-color: red;

    }
  }
  
}
</style>
